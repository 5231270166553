import { mapState, mapActions } from 'vuex'
import dayjs from 'dayjs'
export default {
  computed: {
    ...mapState('common', [
      'globalOptions']),
    titleOption(){
      return[
        {
          name:'Mr 先生',
          id: 1
        },
        {
          name:'Miss 小姐 ',
          id: 2
        },
      ]
    },
    driverNumOption(){
      return[
        {
          name:1,
          id: 1
        },
        {
          name:2,
          id: 2
        },
      ]
    },
    personTypeist(){
      return[
        {
          name:'個人 Peronal',
          id: 1
        },
        {
          name:'公司 Company',
          id: 2
        },
      ]
    },
    accidentsOption(){
      return[
        {
          name:'無意外 No Accident',
          id: 1
        },
        {
          name:'有意外 Had Accident(s)',
          id: 2
        },

      ]
    },
    deductedOption(){
      return[
        {
          name:'無扣分 No Point(s) Deducted',
          id: 1
        },
        {
          name:'有扣分 Poin(s) Deducted',
          id: 2
        },
      ]
    },
    confirmOption(){
      return[
        {
          name:'確定 Confirm',
          id: 1
        },
        {
          name:'不確定 Not Confirm',
          id: 2
        },
      ]
    },
    modificationOption(){
      return[
        {
          name:'無改裝 No Modification',
          id: 2
        },
        {
          name:'有改裝 Modified',
          id: 1
        },
      ]
    },
    personalIdTypOptions() {
      return [
        {
          name: 'HKID Number',
          name_zh: '香港身份證號碼',
          id: 1
        },
        {
          name: 'Passport Number',
          name_zh: '護照號碼',
          id: 2
        },
      ]
    },
    yesOrNoOption(){
      return[
        {
          name:'是 Yes',
          id: 1
        },
        {
          name:'否 No',
          id: 2
        },
      ]
    },
    yesOr2NoOption(){
      return[
        {
          name:'是 Yes',
          id: 2
        },
        {
          name:'否 No',
          id: 1
        },
      ]
    },
    deliveryOption(){
      return[
        {
          name:'不會 No',
          id: 2
        },
        {
          name:'會 Yes',
          id: 1
        },
      ]
    },
    camcorderOption(){
      return[
        {
          name:'無安裝 Not Installed',
          id: 2
        },
        {
          name:'有安裝 Installed',
          id: 1
        },
      ]
    },
    bodyTypeOption(){
      return (class_id) =>{
        let {body_type=[]} = this.globalOptions||{}
        let arr = body_type.filter(item=>item.class_id==class_id)||[]
        return arr?arr[0].option:[]
      }

    },
    ncdOption(){
      return (class_id) =>{
        let {ncd=[]} = this.globalOptions||{}
        let arr = ncd.filter(item=>item.class_id==class_id)||[]
        return arr?arr[0].item:[]
      }

    },
    yearOption(){
      return (class_id) =>{
        let {year=[]} = this.globalOptions||{}
        let arr = year.filter(item=>item.class_id==class_id)||[]
        return arr?arr[0].data:[]
      }

      return[
        {
          name:'電單車 Motor Cycle',
          id: 1
        },
        {
          name:'綿羊仔 Scooter',
          id: 2
        },
      ]
    },
    seatingCapOption(){
      return (class_id) =>{
        let {seating_cap=[]} = this.globalOptions||{}
        let arr = seating_cap.filter(item=>item.class_id==class_id)||[]
        return arr?arr[0].data:[]
      }

      return[
        {
          name:'電單車 Motor Cycle',
          id: 1
        },
        {
          name:'綿羊仔 Scooter',
          id: 2
        },
      ]
    },
    carMakeOption(){
      return (class_id) => {
        let {car_make = []} = this.globalOptions || {}
        let arr = car_make.filter(item => item.class_id == class_id) || []
        return arr ? arr[0].option : []
      }
    },
    coverageOption(){
      return[
        {
          name:'第三者責任保險「三保」Third Party Co...',
          id: 1
        },
      ]
    },

  },
  created() {
    // 檢查是否有遺漏的 options 數據未獲取成功，重新請求一次
    this.checkOptionsData()
  },
  methods: {
    ...mapActions('common', [
      'updateGlobalOptions',
      'checkOptionsData']),
    getAreaName(target, id) {
      let result = ''
      this.globalOptions.area[target].some(item => {
        if (item.id == id) {
          result = item.name
          return true
        }
        return false
      })
      return result
    },
    getAreaData(target, id) {
      let result = {}
      this.globalOptions.area[target].some(item => {
        if (item.id == id) {
          result = item
          return true
        }
        return false
      })
      return result
    },
    getOptionName(target, id, originData) {
      try {
        let data
        if(originData) {
          data= originData
        }
        else {
          const targetArr = target.split('.')
          data = this[targetArr[0]]
          if(targetArr.length > 1) {
            data = this[targetArr[0]][targetArr[1]]
          }
        }
        let result = ''
        data.some(item => {
          if (item.id == id) {
            if(item.name1)
              result = item.name1
            else result = item.name
            return true
          }
          return false
        })
        return result
      } catch (e) {
      }
    },
    getArrOptionName(target, id, originData) {
      try {
        let data
        if(originData) {
          data= originData
        }
        else {
          const targetArr = target.split('.')
          data = this[targetArr[0]]
          if(targetArr.length > 1) {
            data = this[targetArr[0]][targetArr[1]]
          }
        }
        let result = ''
        id.some(item => {
          data.filter((arr_item)=>{
            if (arr_item.id == item) {
              if(arr_item.name1)
                result = result + `${arr_item.name1} `
              else result = result + `${arr_item.name} `
              return true
            }
          })
          return false
        })
        return result
      } catch (e) {
      }
    },
    check18Years(begin, end) {
      if(!begin || !end) return true
      const years18 = dayjs(begin).add(18, 'years').format('YYYY-MM-DD')
      // 結束那一天也算，加一天來判斷
      const endDate = dayjs(end).add(1, 'day').format('YYYY-MM-DD')
      return dayjs(years18).isBefore(endDate)
    },
    check70Years(date) {
      const birthDay = dayjs(date)
      const currentAge = dayjs().diff(birthDay, 'year')
      return currentAge >= 70
    },
    effective_age(begin,end){
      if(!begin || !end) return 0
      const birth = dayjs(end)
      const current = dayjs(begin)
      const age = current.diff(birth, 'year')
      return age
    },
  },

}
